import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro'; // eslint-disable-line import/no-extraneous-dependencies

import slugify from '../utils/slugify';

interface TabsProps extends HTMLElement {
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
  label?: string;
  items: string[];
}

export const TabWrapper = styled.nav<TabsProps>(({ primary, secondary, small }) => [
  tw`flex-row flex overflow-x-scroll w-full justify-between`,
  `min-height: 50px;`
]);

export const TabItem = styled(Link)(() => [
  tw`text-gray-600 py-2 px-6 block hover:text-purple-500 focus:outline-none text-purple-900 border-b-2 font-medium border-gray-300 bg-white w-full text-center`
]);

export const Tabs = ({ items, pathname, ...props }) => {
  return (
    <TabWrapper {...props}>
      {items.map((item) => (
        <TabItem
          to={`${pathname.replace(/\/$/, '')}/${slugify(item).toLowerCase().replace(/\/$/, '')}/`}
          key={item}
          activeStyle={tw`border-purple-800 text-purple-800`}
        >
          {item.replace('-', ' ')}
        </TabItem>
      ))}
    </TabWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/jsx-props-no-spreading
export default Tabs;
